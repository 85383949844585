import { graphql } from 'gatsby';
import React, { FunctionComponent } from 'react';
import { Seo } from 'components/seo/seo';
import { CmsContext } from 'logic/context/cms';
import { App } from '../app/app';

const AboutUsInstitutPage: FunctionComponent<any> = ({
  data: { contentfulAboutUsInstitutPage },
}) => {
  return (
    <CmsContext.Provider value={contentfulAboutUsInstitutPage}>
      <Seo
        title={contentfulAboutUsInstitutPage.pageMeta.metatitle}
        description={contentfulAboutUsInstitutPage.pageMeta.metadescription}
      />
      <App />
    </CmsContext.Provider>
  );
};

export default AboutUsInstitutPage;

export const query = graphql`
  query AboutUsInstitutPage {
    contentfulAboutUsInstitutPage {
      pageMeta {
        metatitle
        metadescription
      }
      firstTitle
      firstSubTitle
      storyContent {
        storyContent
      }
      storySubText
      priceFirst
      priceSecond
      priceLast
      storyImage {
        fluid(quality: 100, maxWidth: 360) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`;
